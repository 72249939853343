/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState} from 'react'
import Modal from 'react-modal'
import CloseIcon from '@material-ui/icons/Close'
import DraftsIcon from '@material-ui/icons/Drafts'
import Balloon  from '../../assets/balloon.svg'
import BricoBrowser from '../../assets/bricoBrowser1line.svg'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const SubscribeModal = ({modalIsOpen, setModalIsOpen}) => {
    const [ email, setEmail ] = useState()
    const [ subscribed, setSubscribed ] = useState(false)
    const [ msg, setMsg ] = useState('')

    const customStyles = {
        content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)'
        }
    }

    function closeModal(){
        setModalIsOpen(false)
    }
    
    const subscribeEmail = (e) => {
        setEmail(e)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('eeee',email)
        addToMailchimp(email)
            .then(data => {
                console.log('data',data.msg)
                if(data.msg) {
                    setSubscribed(true)
                    setMsg(data.msg)
                }
            })
            .catch(err => console.log('eerr',err))
    }

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Subscribe modal"
                ariaHideApp={false}
            >
                <div>
                    <div className="text-right">
                        <button onClick={closeModal}><CloseIcon/></button>
                    </div>  
                    <div className="w-full">
                        <div className="flex justify-center">
                            <BricoBrowser className="logoTitle"/>
                        </div>
                        {subscribed ?<> 
                            <Balloon className="w-1/3 flex justify-center m-auto items-center"/> 
                            <h4 className="text-xl py-4 uppercase tracking-wider">Thank you for subscribing <br/> to our newsletter.</h4>
                            <p> We are thrilled to have you on board. <br/> You are now subscribed to receive <br/> email updates about launching date, <br/> new features and updates.</p>
                            <p>{msg}</p>
                        </>
                            : <> 
                                <h4 className="text-xl py-2 uppercase tracking-wider">Let&apos;s stay in touch</h4>
                                <div>
                                    <DraftsIcon className="icons"/>
                                </div>
                                <p className="w-full md:w-2/3 m-auto text-center flex mb-3">Join our newsletter, so that we reach out to you with our best news and offers.</p>

                                <form className="" onSubmit={(e)=>handleSubmit(e)}>
                                    <input
                                        onChange={(e)=>subscribeEmail(e.target.value)}
                                        className="bg-gray-100 appearance-none border-2 mb-2 border-gray-200 rounded w-2/3 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-100" id="inline-full-name" type="email" placeholder="Email"/>
                                    <button className="bg-yellowColor ml-4 text-mainColor transition-shadow py-2 px-4 rounded" type="submit">Subscribe</button>
                                </form>
                                <div className="my-4">
                                    <div onClick={closeModal} className="text-mainColor tracking-widest cursor-pointer">No, thanks</div>
                                </div>
                            </>}
                    </div>
                </div>
               
            </Modal>
        </div>
    )
}
export default SubscribeModal